import { useState } from 'react';

import { useNavigate } from "react-router-dom";


import {AppBar, Toolbar, Typography, IconButton, Box, Menu, MenuItem, Drawer, Paper, Divider} from '@mui/material'
import CssBaseline from '@mui/material/CssBaseline';

import MenuIcon from '@mui/icons-material/Menu';
import AdbIcon from '@mui/icons-material/Adb';
import {  AccountCircle } from "@mui/icons-material";


import { styled } from '@mui/material/styles';


import useAuth from "../../hooks/useAuth";

import useLogout from "../../hooks/useLogout";
import Aside from './Aside';



const UserBox = styled(Box)(({theme}) => ({
    display: "flex",
    alignItems: "center",
    gap: "10px",
    [theme.breakpoints.up("sm")] : {
       // display: "none"
    }
}))

const Navbar = () => {

    const { auth } = useAuth();
    const [openMenuUser, setOpenMenuUser] = useState(false);
    const [openAside, setOpenAside] = useState(false);


    const navigate = useNavigate();
    const logout = useLogout();

    const signOut = async () => {
        await logout();
        navigate('/');
    }


  return (
    <>
        <AppBar position="sticky">
            <Toolbar>
                <IconButton edge='start' color='inherit' onClick={e=>setOpenAside(true)}>
                    <MenuIcon sx={{display:{xs:"block", sm: "none"}}}/>
                </IconButton>


                {/* Aside */}
                <Drawer anchor="left" open={openAside} onClose={() => setOpenAside(false)} >
                    <Paper style={{width:"240px"}} elevation={0}>
                        <div style={{padding: "10px", display: 'flex', alignItems:'center'}}>
                            <IconButton edge="end" color='inherit' onClick={() => setOpenAside(false)}>
                                <MenuIcon/>
                            </IconButton>
                            <Typography style={{marginLeft: 30}}>Tecno App.</Typography>
                        </div>
                        
                        <Box>
                            <Aside/>
                        </Box>
                        
                    </Paper>
                </Drawer>
                

                <AdbIcon sx={{ display: 'flex', mr: 1 }} />
                <Typography variant="h6" component="div" 
                sx={{ 
                    flexGrow: 1,
                    fontFamily: 'monospace',
                    fontWeight: 700,
                    letterSpacing: '.3rem',
                    color: 'inherit',
                    textDecoration: 'none',
                }}
                >
                OSET
                </Typography>
                
                {/* <Button variant="text" color="inherit">Log out</Button> */}
                <UserBox onClick={e=>setOpenMenuUser(true)}>
                    <AccountCircle/>
                    <Typography variant="span">{auth?.username}</Typography>
                </UserBox>
            </Toolbar>

        </AppBar>
        <Menu
            id="demo-positioned-menu"
            aria-labelledby="demo-positioned-button"
            open={openMenuUser}
            onClose={e=>setOpenMenuUser(false)}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
        >
            {/* <MenuItem>Profile</MenuItem>
            <MenuItem>My account</MenuItem> */}
            <MenuItem onClick={signOut}>Logout</MenuItem>
        </Menu>


        

    </>
  )
}

export default Navbar