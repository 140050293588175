import { useRef, useState, useEffect } from "react";

import { useParams, useNavigate, useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";

import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";

import "reactjs-popup/dist/index.css";

import EmailIcon from "@mui/icons-material/Email";
import SaveIcon from "@mui/icons-material/Save";

import useAxiosPrivate from "../../hooks/useAxiosPrivate";

import Title from "../layout/Title";

import TextField from "@mui/material/TextField";

import LockIcon from "@mui/icons-material/Lock";

import BadgeIcon from "@mui/icons-material/Badge";

const UserSingle = () => {
  const [userId, setUserId] = useState(0);

  const axiosPrivate = useAxiosPrivate();

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm({pwd: ""});

  let { id } = useParams();

  useEffect(() => {
    console.log("id", id);

    if (id != undefined) {
      let isMounted = true;
      const controller = new AbortController();

      const getUser = async () => {
        try {
          const response = await axiosPrivate.get(`/users/${id}`, {
            signal: controller.signal,
          });

          const data = response.data;
          console.log("data");
          console.log(data);

          if (isMounted) {

            setValue('email', data['email']);
            setValue('username', data['username']);
            setValue('password', '');
            //setValue('active', data['active']);
            setUserId(data._id);
          }
        } catch (err) {
          console.error(err);
        }
      };

      getUser();

      return () => {
        isMounted = false;
        controller.abort();
      };
    }
  }, []);

  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from?.pathname || "/admin/users";

  const [errMsg, setErrMsg] = useState("");

  const errRef = useRef();

  console.log(errors);
  //console.log(watch("example"));

  const onSubmit = async (data) => {
    console.log("userId", userId);

    console.log('save data', data);
    try {
      let response = {};
      if (userId == 0) {

        data['roles'] = {"Support": 1985};
        
        response = await axiosPrivate.post("/register", JSON.stringify(data), {
          headers: { "Content-Type": "application/json" },
          withCredentials: true,
        });
      } else {
        response = await axiosPrivate.put(
          `users/${userId}`,
          JSON.stringify(data),
          {
            headers: { "Content-Type": "application/json" },
            withCredentials: true,
          }
        );
      }

      //setUserId(response?.data?._id);
      navigate(from, { replace: true });
    } catch (err) {
      if (!err?.response) {
        setErrMsg("No Server Response");
      } else if (err.response?.status === 400) {
        setErrMsg("Missing Username or Password");
      } else if (err.response?.status === 401) {
        setErrMsg("Unauthorized");
      } else {
        setErrMsg("Error al Crear el Usuario");
      }
      errRef.current.focus();
    }
  };

  return (
    <Paper elevation={6} sx={{ p: 3, mb: 3 }}>
      <Title>{userId ? "Editar " : "Nuevo "}Usuario</Title>

      <p
        ref={errRef}
        className={errMsg ? "errmsg" : "offscreen"}
        aria-live="assertive"
      >
        {errMsg}
      </p>

      <br />
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item xs={12} md={6} lg={6}>
            <Box sx={{ display: "flex", alignItems: "flex-end" }}>
              <BadgeIcon sx={{ color: "action.active", mr: 1, my: 0.5 }} />
              <TextField
                autoComplete="off"
                label="Nombre"
                InputLabelProps={{ shrink: true }}
                fullWidth
                variant="standard"
                required
                {...register("username", { required: true })}
              />
              <small>{errors.username?.message}</small>
            </Box>
          </Grid>

          <Grid item xs={12} md={6}>
            <Box sx={{ display: "flex", alignItems: "flex-end" }}>
              <EmailIcon sx={{ color: "action.active", mr: 1, my: 0.5 }} />
              <TextField
                label="Correo"
                variant="standard"
                fullWidth
                autoComplete="off"
                required
                {...register("email", {
                  required: true,
                  pattern: /^\S+@\S+$/i,
                })}
                InputLabelProps={{ shrink: true }}
              />
              <small>{errors.email?.message}</small>
            </Box>
          </Grid>

          <Grid item xs={12} md={6}>
            <Box sx={{ display: "flex", alignItems: "flex-end" }}>
              <LockIcon sx={{ color: "action.active", mr: 1, my: 0.5 }} />
              <TextField
                label="Contraseña"
                variant="standard"
                type="password"
                name="password"
                autoComplete="off"
                {...register("password")}
                InputLabelProps={{ shrink: true }}
                fullWidth
              />
              <small>{errors.password?.message}</small>
            </Box>
          </Grid>

          <Grid item xs={12} sx={{ marginTop: 3 }}>
            <Button type="submit" variant="contained" endIcon={<SaveIcon />}>
              Guardar
            </Button>
          </Grid>
        </Grid>
      </form>
    </Paper>
  );
};

export default UserSingle;
