import { useState, useEffect } from "react";
import { useNavigate, useLocation, Link } from "react-router-dom";

import useAxiosPrivate from "../hooks/useAxiosPrivate";

import { Paper, Typography, Button, IconButton, Stack } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import Title from "./layout/Title";

import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from '@mui/icons-material/Edit';

import useAuth from "../hooks/useAuth";

const ROLES = {
  'Admin': 1950,
  'Support': 1985,
  'User': 2022
}

const columns = [
  { field: "order_no", headerName: "Order No", width: 70 },
  { field: "status", headerName: "Estado", width: 90 },
  { field: "date_order", headerName: "Fecha", width: 130 },
  { field: "business_name", headerName: "Razón Social", width: 150 },
  { field: "identification", headerName: "Identificación", width: 130 },
  { field: "phone", headerName: "Teléfono", width: 130 },
  { field: "email", headerName: "E-mail", width: 130 },
  { field: "technical_user_id", headerName: "Técnico", width: 130, valueGetter: (params) => `${params.row.technical_user_id.username || ""}`},
  {
    field: "action",
    headerName: "Acciones",
    width: 130,
    renderCell: (params) => {

      const { row } = params;
      const id = row.id;


      const onDelete = async (e) => {
        console.log("Delete row");

        if (!window.confirm('¿Esta seguro de eliminar la orden?')) {
          return;
        }

        const API_URL = `orders/${id}`;
        try {
          let response = {};
          await row.connection.delete(API_URL, {}, { withCredentials: true });
    
          //getOrders();
          console.log('Orden No', response?.data?.order_no);
    
        } catch (err) {
          //setErrMsg("Error al Eliminar la Orden");
        }
        
      };
      return (
        <>
          <Stack direction="row" spacing={0}>
            <IconButton aria-label="edit" component={Link} to={`/admin/orders/${id}`}>
              <EditIcon />
            </IconButton>
            { row?.isAdmin ? (
              <IconButton aria-label="delete" onClick={() => onDelete()}>
                <DeleteIcon />
              </IconButton>
            ) : (<></>)}
          </Stack>
        </>
      );
    },
  },
];


export default function OrderList() {
  const { auth } = useAuth();
  const isAdmin = auth?.roles?.find(role => ROLES.Admin == role);

  const [orders, setOrders] = useState();
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const location = useLocation();


  useEffect(() => {
    let isMounted = true;
    const controller = new AbortController();

    const getOrders = async () => {
      try {
        const response = await axiosPrivate.get("/orders", {
          signal: controller.signal,
        });

        const data = response.data;
        //Modify rows here:
        const modifiedData = data.map(element => {
          return { ...element, connection: axiosPrivate, isAdmin };
        });
        isMounted && setOrders(modifiedData);
      } catch (err) {
        console.error(err);
        navigate("/login", { state: { from: location }, replace: true });
      }
    };

    getOrders();

    return () => {
      isMounted = false;
      controller.abort();
    };
  }, []);

  return (
    <Paper elevation={6} sx={{ p: 2 }}>
      <Title>Ordenes de Servicio</Title>

      <Button variant="outlined" size="small" component={Link} to="/admin/orders/new" >
        Crear
      </Button>
      <br />
      <br />
      {orders?.length > 0 ? (
        <DataGrid
          rows={orders}
          columns={columns}
          pageSize={15}
          rowsPerPageOptions={[5]}
          //checkboxSelection
          autoHeight
          density="compact"
        />
      ) : (
        <Typography>No se encontraron ordenes</Typography>
      )}
    </Paper>
  );
}
