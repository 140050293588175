import { useState } from "react";

import { Box, createTheme, Stack, ThemeProvider, CssBaseline } from "@mui/material";

import Feed from './Feed';
import Footer from "./Footer"
import Navbar from "./Navbar"
import Sidebar from './Sidebar';


const Template = () => {

  const [mode, setMode] = useState("light");

  const darkTheme = createTheme({
    palette: {
      mode: mode,
    },
  });


  return (
    <ThemeProvider theme={darkTheme}>
      <CssBaseline/>

      <Box bgcolor={"background.default"} color={"text.primary"}>
        <Navbar/>
        <Stack direction="row" p={{xs:2, md:4 }} justifyContent="space-between">
          <Sidebar setMode={setMode} mode={mode}/>
          <Feed/>
        </Stack>
        <Footer sx={{ mt: 8, mb: 4 }}/>
      </Box>
    </ThemeProvider>
  )
}

export default Template