import { useRef, useState, useEffect } from "react";

import { useParams, useNavigate, useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";

import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";

import "reactjs-popup/dist/index.css";

import SaveIcon from "@mui/icons-material/Save";

import useAxiosPrivate from "../../hooks/useAxiosPrivate";

import Title from "../layout/Title";

import TextField from "@mui/material/TextField";

import { InputLabel, MenuItem, Select } from "@mui/material";

const SurveySingle = () => {

  const [serviceEvaluate, setServiceEvaluate] = useState('Compra de maquinaria, productos y/o insumos');
  const handleChangeServiceEvalate = (event) => {
    setServiceEvaluate(event.target.value);
  };

  const [experience, setExperience] = useState('');
  const handleChangeExperience = (event) => {
    setExperience(event.target.value);
  };

  const [provisionService, setProvisionService] = useState('');
  const handleChangeProvisionService = (event) => {
    setProvisionService(event.target.value);
  };

  const [satisfiedTechnical, setSatisfiedTechnical] = useState('');
  const handleChangeSatisfiedTechnical = (event) => {
    setSatisfiedTechnical(event.target.value);
  };

  const [rateQuality, setRateQuality] = useState('');
  const handleChangeRateQuality = (event) => {
    setRateQuality(event.target.value);
  };

  const [recommend, setRecommend] = useState('Si');
  const handleChangeRecommend = (event) => {
    setRecommend(event.target.value);
  };
  

  const [surveyId, setSurveyId] = useState(0);

  const axiosPrivate = useAxiosPrivate();

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm();

  let { id } = useParams();

  
  useEffect(() => {
    console.log("id", id);

    if (id != undefined) {
      let isMounted = true;
      const controller = new AbortController();

      const getSurvey = async () => {
        try {
          const response = await axiosPrivate.get(`/surveys/${id}`, {
            signal: controller.signal,
          });

          const data = response.data;
          console.log("data");
          console.log(data);

          if (isMounted) {
            for (const property in data) {
              console.log(`${property}: ${data[property]}`);
              setValue(property, data[property]);
            }

            setSurveyId(data._id);

            setRecommend(data.recommend);
            setServiceEvaluate(data.service_evaluate);
            setExperience(data.experience);
            setProvisionService(data.provision_service);
            setSatisfiedTechnical(data.satisfied_technical);
            setRateQuality(data.rate_quality);

          }
        } catch (err) {
          console.error(err);
        }
      };

      getSurvey();

      return () => {
        isMounted = false;
        controller.abort();
      };
    }
  }, []);

  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from?.pathname || "/admin/surveys";

  const [errMsg, setErrMsg] = useState("");

  const errRef = useRef();

  //console.log(watch("example"));

  const onSubmit = async (data) => {

    console.log("surveyId", surveyId);
    console.log("save data", data);

    try {
      let response = {};
      if (surveyId == 0) {
        data["roles"] = { Support: 1985 };

        response = await axiosPrivate.post("/surveys", JSON.stringify(data), {
          headers: { "Content-Type": "application/json" },
          withCredentials: true,
        });
      } else {
        response = await axiosPrivate.put(
          `surveys/${surveyId}`,
          JSON.stringify(data),
          {
            headers: { "Content-Type": "application/json" },
            withCredentials: true,
          }
        );
      }

      //setUserId(response?.data?._id);
      navigate(from, { replace: true });
    } catch (err) {
      if (!err?.response) {
        setErrMsg("No Server Response");
      } else if (err.response?.status === 400) {
        setErrMsg("Missing fields");
      } else if (err.response?.status === 401) {
        setErrMsg("Unauthorized");
      } else {
        setErrMsg("Error al Crear la Encuesta");
      }
      errRef.current.focus();
    }
  };

  return (
    <Paper elevation={6} sx={{ p: 3, mb: 3 }}>
      <Title>{surveyId ? "Editar " : "Nueva "}Encuesta</Title>

      <p
        ref={errRef}
        className={errMsg ? "errmsg" : "offscreen"}
        aria-live="assertive"
      >
        {errMsg}
      </p>

      <br />
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item xs={12} md={6} lg={6}>
            <TextField
              autoComplete="off"
              label="Cliente"
              InputLabelProps={{ shrink: true }}
              required
              fullWidth
              name="customer_name"
              variant="standard"
              {...register("customer_name", { required: true })}
            />
            <small>{errors.customer_name?.message}</small>
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              type="date"
              label="Fecha"
              name="date_survey"
              required
              {...register("date_survey", { required: true })}
              InputLabelProps={{
                shrink: true,
              }}
              fullWidth
              variant="standard"
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              autoComplete="off"
              label="Persona que Diligencia."
              InputLabelProps={{ shrink: true }}
              fullWidth
              required
              name="name_diligence"
              variant="standard"
              {...register("name_diligence", { required: true })}
            />
            <small>{errors.name_diligence?.message}</small>
          </Grid>

          <Grid item xs={12} md={12}>
            <Typography variant="body2" align="justify" gutterBottom>
              Para TECNOEMBALAJE S.A.S. es de gran importancia conocer su nivel de satisfacción respecto a los productos y servicios que ofrecemos.
              <br/>
              Por favor, tome unos minutos de su tiempo para brindarnos sus comentarios a través de este cuestionario y así nosotros podremos mejorar y brindarle cada día un mejor servicio. 
              <br/>
              Califique su grado de satisfacción en los siguientes puntos, teniendo en cuenta que el 1 implica el mínimo grado de satisfacción y 5 el máximo.
            </Typography>
          </Grid>

          <Grid item xs={12} md={12}>
            <FormControl variant="standard">
              <FormLabel id="demo-error-radios">
                Seleccione el Servicio a evaluar
              </FormLabel>
              <RadioGroup
                aria-labelledby="demo-error-radios"
                name="service_evaluate"
                value={serviceEvaluate}
                onChange={handleChangeServiceEvalate}
              >
                {/* <FormControlLabel
                  value="Compra de maquinaria, productos y/o insumos"
                  control={<Radio {...register("service_evaluate")} />}
                  label="Compra de maquinaria, productos y/o insumos"
                />*/}
                <FormControlLabel
                  value="Servicio Técnico"
                  control={<Radio {...register("service_evaluate")} />}
                  label="Servicio Técnico"
                />
              </RadioGroup>
            </FormControl>
          </Grid>

          {/*<Grid item xs={12} md={12}>
            <FormControl variant="standard" sx={{ width: "100%" }}>
              <InputLabel id="experience">
                ¿Cómo fue su experiencia durante la compra o la prestación del
                servicio?
              </InputLabel>
              <Select
                labelId="experience"
                id="experience"
                label="¿Cómo fue su experiencia durante la compra o la prestación del servicio?"
                fullWidth
                {...register("experience")}
                value={experience}
                onChange={handleChangeExperience}
                required
              >
                <MenuItem>
                  <em>None</em>
                </MenuItem>
                <MenuItem value={1}>1</MenuItem>
                <MenuItem value={2}>2</MenuItem>
                <MenuItem value={3}>3</MenuItem>
                <MenuItem value={4}>4</MenuItem>
                <MenuItem value={5}>5</MenuItem>
              </Select>
            </FormControl>
          </Grid>*/}

          <Grid item xs={12} md={12}>
            <FormControl variant="standard" sx={{ width: "100%" }}>
              <InputLabel id="provision_service">
                ¿Cómo califica el proceso de entrega de la compra o prestación
                del servicio?
              </InputLabel>
              <Select
                labelId="provision_service"
                id="provision_service"
                label="¿Cómo califica el proceso de entrega de la compra o prestación del servicio?"
                fullWidth
                {...register("provision_service")}
                value={provisionService}
                onChange={handleChangeProvisionService}
                required
              >
                <MenuItem>
                  <em>Ninguno</em>
                </MenuItem>
                <MenuItem value={1}>1 Mínimo grado de Satisfacción</MenuItem>
                <MenuItem value={2}>2</MenuItem>
                <MenuItem value={3}>3</MenuItem>
                <MenuItem value={4}>4</MenuItem>
                <MenuItem value={5}>5 Máximo grado de Satisfacción</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12} md={12}>
            <FormControl variant="standard" sx={{ width: "100%" }}>
              <InputLabel id="satisfied_technical">
                ¿Qué tan satisfecho esta con la forma en que nuestro asesor y/o
                representante de servicio técnico atendió su consulta?
              </InputLabel>
              <Select
                labelId="satisfied_technical"
                label="Age"
                fullWidth
                {...register("satisfied_technical")}
                value={satisfiedTechnical}
                onChange={handleChangeSatisfiedTechnical}
                required
              >
                <MenuItem>
                  <em>Ninguno</em>
                </MenuItem>
                <MenuItem value={1}>1 Mínimo grado de Satisfacción</MenuItem>
                <MenuItem value={2}>2</MenuItem>
                <MenuItem value={3}>3</MenuItem>
                <MenuItem value={4}>4</MenuItem>
                <MenuItem value={5}>5 Máximo grado de Satisfacción</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12} md={12}>
            <FormControl variant="standard" sx={{ width: "100%" }}>
              <InputLabel id="rate_quality">
                ¿Cómo califica la calidad de nuestros productos y/o servicios?
              </InputLabel>
              <Select
                labelId="rate_quality"
                id="demo-simple-select-standard"
                label="¿Cómo califica la calidad de nuestros productos y/o servicios?"
                fullWidth
                {...register("rate_quality")}
                value={rateQuality}
                onChange={handleChangeRateQuality}
                required
              >
                <MenuItem>
                  <em>Ninguno</em>
                </MenuItem>
                <MenuItem value={1}>1 Mínimo grado de Satisfacción</MenuItem>
                <MenuItem value={2}>2</MenuItem>
                <MenuItem value={3}>3</MenuItem>
                <MenuItem value={4}>4</MenuItem>
                <MenuItem value={5}>5 Máximo grado de Satisfacción</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12} md={12}>
            <FormControl variant="standard">
              <FormLabel id="demo-error-radios">
                ¿Recomendaría los productos y servicios de Tecnoembalaje?
              </FormLabel>
              <RadioGroup
                aria-labelledby="demo-error-radios"
                name="recommend"
                value={recommend}
                onChange={handleChangeRecommend}
              >
                <FormControlLabel
                  control={<Radio {...register("recommend")} />}
                  label="Si"
                  value="Si"
                />
                <FormControlLabel
                  value="No"
                  control={<Radio {...register("recommend")} />}
                  label="No"
                />
              </RadioGroup>
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              autoComplete="off"
              label="Si su respuesta es Negativa, amplié su respuesta"
              InputLabelProps={{ shrink: true }}
              fullWidth
              name="answer_no"
              variant="standard"
              {...register("answer_no")}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              autoComplete="off"
              label="¿En que considera que podríamos mejorar?"
              InputLabelProps={{ shrink: true }}
              fullWidth
              name="could_improve"
              variant="standard"
              {...register("could_improve")}
            />
          </Grid>

          <Grid item xs={12} sx={{ marginTop: 3 }}>
            <Button type="submit" variant="contained" endIcon={<SaveIcon />}>
              Guardar
            </Button>
          </Grid>
        </Grid>
      </form>
    </Paper>
  );
};

export default SurveySingle;
