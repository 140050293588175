
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';


import EmailIcon from '@mui/icons-material/Email';
import ContactPhoneIcon from '@mui/icons-material/ContactPhone';


export default function CustomerData({register, orderData, errors}) {

  console.log('CustomerData');
  console.log(errors);

  return (
    <>
      <Typography variant="h6" gutterBottom>
        Datos del Cliente
      </Typography>

      <Grid container spacing={3}>


        <Grid item xs={12} sm={6}>
          <TextField
            required
            type="date"
            label="Fecha" 
            name="date_order"
            {...register("date_order")}
            InputLabelProps={{
              shrink: true,
            }}
            fullWidth
            variant="standard"
          />
          <small>{errors.date_order?.message}</small>
        </Grid>

        <Grid item xs={6} md={3}>
            <TextField type="time"
                required
                label="Hora de Ingreso"
                variant="standard"
                fullWidth
                InputLabelProps={{
                    shrink: true,
                }}
                {...register("checkin_time", {required: true})}
            />
        </Grid>

        <Grid item xs={6} md={3}>
            <TextField 
                required
                type="time"
                label="Hora de Salida"
                variant="standard"
                fullWidth
                InputLabelProps={{
                    shrink: true,
                }}
                {...register("checkout_time", {required: true})}/>
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            required
            name="business_name"
            {...register("business_name", {
              required: "This is required", 
              minLength: {
                  value: 4,
                  message: "Min length is 4"
              }
              })
            } 
            label="Nombre o razón social" 
            fullWidth
            variant="standard"
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            required
            name="identification"
            label="NIT"
            fullWidth
            variant="standard"
            {...register("identification", {
              required: "This is required", 
              minLength: {
                  value: 6,
                  message: "Min length is 6"
              }
              })
            }
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            name="address"
            required
            label="Dirección"
            fullWidth
            variant="standard"
            {...register("address", {required: true})}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            name="city"
            label="Ciudad"
            required
            fullWidth
            variant="standard"
            {...register("city", {required: true})}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>

        <Grid item xs={12} md={6}>
                    
            <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                <ContactPhoneIcon sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
                <TextField 
                    label="Teléfono y/o Cel"
                    variant="standard" 
                    fullWidth
                    required
                    name="phone"
                    {...register("phone", {required: true})}
                    InputLabelProps={{
                      shrink: true,
                    }}
                />
            </Box>
        </Grid>

        <Grid item xs={12} md={6}>
            <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                <EmailIcon sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
                <TextField 
                    label="Correo"
                    variant="standard" 
                    fullWidth
                    required
                    {...register("email", {required: true, pattern: /^\S+@\S+$/i})}
                    InputLabelProps={{
                      shrink: true,
                    }}
                />
                <small>{errors.email?.message}</small>
            </Box>
            
        </Grid>

        <Grid item xs={12} md={6}>
            <TextField 
                label="Cargo"
                variant="standard"
                fullWidth
                required
                {...register("position")}
                InputLabelProps={{
                  shrink: true,
                }}
            />
        </Grid>

        <Grid item xs={12} md={6}>
            <TextField 
                label="Servicio solicitado por"
                variant="standard"
                fullWidth
                required
                {...register("requested_by")}
                InputLabelProps={{
                  shrink: true,
                }}
            />
            <small>{errors.requested_by?.message}</small>
        </Grid>

      </Grid>
    </>
  );
}