import { Link } from "react-router-dom"

const LinkPage = () => {
    return (
        <section>
            <h1>Links</h1>
            <br />
            <br />
            <h2>Public</h2>
            <br />

            <Link to="/login">Iniciar Sesión</Link>
            {/* <Link to="/register">Register</Link> */}
            <br />
            <br />
            <h2>Private</h2>
            <br />

            <Link to="/">Menú</Link>
            <Link to="/orders">Ordenes de Servicio</Link>
            <Link to="/admin">Usuarios</Link>
            <Link to="/editor">Editors Page</Link>
        </section>
    )
}

export default LinkPage
