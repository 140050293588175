import TextField from '@mui/material/TextField';


import { useRef, useState, useEffect } from "react";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";

import SignatureCanvas from "react-signature-canvas";

import Button from "@mui/material/Button";
import Popup from "reactjs-popup";

export default function Signatures({ register, orderData, setValue, errors }) {
  const sigCanvasCustomer = useRef({});
  const sigCanvasTechnical = useRef({});
  const [imageCustomerURL, setImageCustomerURL] = useState("");
  const [imageTechnicalURL, setImageTechnicalURL] = useState("");

  const clearCanvasCustomer = () => sigCanvasCustomer.current.clear();
  const clearCanvasTechnical = () => sigCanvasTechnical.current.clear();

  const saveSignatureCustomer = () => {
    const base64 = sigCanvasCustomer.current
      .getTrimmedCanvas()
      .toDataURL("image/png");
    setValue("signature_customer", base64);
    setImageCustomerURL(base64);
  };

  const saveSignatureTechnical = () => {
    const base64 = sigCanvasTechnical.current
      .getTrimmedCanvas()
      .toDataURL("image/png");
    setValue("signature_technical", base64);
    setImageTechnicalURL(base64);
  };

  useEffect(() => {
    setImageCustomerURL(orderData.signature_customer);
    setImageTechnicalURL(orderData.signature_technical);
  }, [orderData]);

  return (
    <>
      <Typography variant="body2" align="justify" gutterBottom>
        “De conformidad con lo establecido en el Estatuto del Consumidor, el Decreto 1413 de 2018 y demás normas que lo complementan, se informa al cliente que, en caso de no retirar la maquina mencionada en esta orden de servicio después de haber sido notificado de su reparación y disponibilidad para ser recogido, se entenderá que ha renunciado a la propiedad sobre el mismo. En consecuencia, el Servicio Técnico de <b>Tecnoembalaje SAS</b>, quedará autorizada a proceder a la destrucción o disposición del equipo sin responsabilidad alguna por daños, pérdidas o reclamos. Cualquier costo incurrido por la empresa en relación con la disposición del equipo será cargado al propietario.
        <br/><br/>
        Se informa al cliente que, en virtud de lo establecido en la normativa citada, la empresa mantendrá a disposición del cliente el equipo por un <b>plazo máximo de dos (2) meses</b> para su retiro antes de proceder a su disposición o destrucción.”
      </Typography>
      <br/>
      <Typography variant="h6" gutterBottom>
        Firmar
      </Typography>

      <Grid container spacing={3}>
        <Grid item xs={12} sx={{ marginTop: 12 }}>
          <Grid
            container
            rowSpacing={2}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          >
            <Grid item xs={12} md={6}>
              <span>
                {imageCustomerURL ? (
                  <img
                    src={imageCustomerURL}
                    style={{
                      display: "block",
                      margin: "0 auto",
                      //border: "1px solid black",
                      width: "250px",
                      marginBottom: "5px",
                    }}
                  />
                ) : null}
                <input
                  type="hidden"
                  value={imageCustomerURL}
                  {...register("signature_customer")}
                />
                <hr />
                <Popup
                  modal
                  trigger={<Button variant="text">FIRMA CLIENTE</Button>}
                  closeOnDocumentClick={false} /* position="right center" */
                >
                  {(close) => (
                    <>
                      <SignatureCanvas
                        ref={sigCanvasCustomer}
                        penColor="black"
                        canvasProps={{
                          className: "sigCanvas",
                        }}
                        fullWidth
                      />
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Button
                          variant="outlined"
                          size="small"
                          onClick={saveSignatureCustomer}
                        >
                          Listo!
                        </Button>
                        <Button
                          variant="outlined"
                          size="small"
                          onClick={clearCanvasCustomer}
                        >
                          Limpiar
                        </Button>
                        <Button variant="outlined" size="small" onClick={close}>
                          Cerrar
                        </Button>
                      </div>
                    </>
                  )}
                </Popup>
                <TextField
                  label="Nombre del Cliente"
                  name="signature_customer_name"
                  {...register("signature_customer_name", { required: true })}
                  
                  fullWidth
                  variant="standard"
                />
              </span>
            </Grid>

            <Grid item xs={12} md={6}>
              <span>
                {imageTechnicalURL ? (
                  <img
                    src={imageTechnicalURL}
                    style={{
                      display: "block",
                      margin: "0 auto",
                      //border: "1px solid black",
                      width: "250px",
                      marginBottom: "5px",
                    }}
                  />
                ) : null}
                <input
                  type="hidden"
                  value={imageTechnicalURL}
                  {...register("signature_technical")}
                />
                <hr />
                <Popup
                  modal
                  trigger={<Button variant="text">FIRMA TÉCNICO</Button>}
                  closeOnDocumentClick={false} /* position="right center" */
                >
                  {(close) => (
                    <>
                      <SignatureCanvas
                        ref={sigCanvasTechnical}
                        penColor="black"
                        canvasProps={{
                          className: "sigCanvas",
                        }}
                        fullWidth
                      />
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Button variant="outlined" size="small" onClick={close}>
                          Cerrar
                        </Button>
                        <Button
                          variant="outlined"
                          size="small"
                          onClick={saveSignatureTechnical}
                        >
                          Listo!
                        </Button>
                        <Button
                          variant="outlined"
                          size="small"
                          onClick={clearCanvasTechnical}
                        >
                          Limpiar
                        </Button>
                      </div>
                    </>
                  )}
                </Popup>
              </span>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
<Grid container spacing={3}></Grid>;
