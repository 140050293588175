import { useState, useEffect } from "react";

import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Checkbox from "@mui/material/Checkbox";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';

import DetailProducts from "./DetailProducts";

export default function ServiceData({ register, orderData, errors }) {
  let items = {
    diagnostic: false,
    postsale: false,
    corrective: false,
    warranty: false,
    test: false,
    preventive: false,
    deliveryCertificate: false,
    workshop: false,
  };

  if (orderData?.type_service) {
    orderData?.type_service?.map((value) => {
      if (value === "Diagnostico") {
        items["diagnostic"] = true;
      }

      if (value === "Garantía pos-venta") {
        items["postsale"] = true;
      }

      if (value === "Correctivo") {
        items["corrective"] = true;
      }

      if (value === "Garantía servicio") {
        items["warranty"] = true;
      }

      if (value === "Prueba") {
        items["test"] = true;
      }

      if (value === "Preventivo") {
        items["preventive"] = true;
      }

      if (value === "Taller") {
        items["workshop"] = true;
      }

      if (value === "Acta de Entrega") {
        items["deliveryCertificate"] = true;
      }
    });
  }

  const [typeService, setTypeService] = useState(items);

  const handleChangeTypeService = (event) => {
    const targetName = event.target.getAttribute("data-name");

    setTypeService({
      ...typeService,
      [targetName]: event.target.checked,
    });
  };

  let {
    diagnostic,
    postsale,
    corrective,
    warranty,
    test,
    preventive,
    deliveryCertificate,
    workshop,
  } = typeService;

  let defaultServiceSatisfacion = "";
  if (orderData?.service_satisfaction) {
    defaultServiceSatisfacion = orderData.service_satisfaction;
  }

  const [serviceSatisfaction, setServiceSatisfaction] = useState(
    defaultServiceSatisfacion
  );
  const handleChangeServiceSatisfaction = (event) => {
    setServiceSatisfaction(event.target.value);
  };


  const [equipment, setEquipment] = useState('');
  const handleChangeEquipment = (event) => {
    setEquipment(event.target.value);
  };


  useEffect(() => {
    setEquipment(orderData?.equipment);
  }, [orderData]);


  return (
    <>
      <Typography variant="h6" gutterBottom>
        Detalle del Servicio
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          {/* Tipo de Servicio */}
          <Grid
            container
            rowSpacing={2}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          >
            <Grid item xs={6} sm={4}>
              <FormControlLabel
                value="Diagnostico"
                control={
                  <Checkbox
                    checked={diagnostic}
                    onChange={handleChangeTypeService}
                    inputProps={{ "data-name": "diagnostic" }}
                  />
                }
                label="Diagnostico"
                labelPlacement="end"
                {...register("type_service[]")}
              />
            </Grid>
            <Grid item xs={6} sm={4}>
              <FormControlLabel
                value="Garantía pos-venta"
                control={
                  <Checkbox
                    checked={postsale}
                    onChange={handleChangeTypeService}
                    inputProps={{ "data-name": "postsale" }}
                  />
                }
                label="Garantía pos-venta"
                labelPlacement="end"
                {...register("type_service[]")}
              />
            </Grid>
            <Grid item xs={6} sm={4}>
              <FormControlLabel
                value="Correctivo"
                control={
                  <Checkbox
                    checked={corrective}
                    onChange={handleChangeTypeService}
                    inputProps={{ "data-name": "corrective" }}
                  />
                }
                label="Correctivo"
                labelPlacement="end"
                {...register("type_service[]")}
              />
            </Grid>
            <Grid item xs={6} sm={4}>
              <FormControlLabel
                value="Garantía servicio"
                control={
                  <Checkbox
                    checked={warranty}
                    onChange={handleChangeTypeService}
                    inputProps={{ "data-name": "warranty" }}
                  />
                }
                label="Garantía servicio"
                labelPlacement="end"
                {...register("type_service[]")}
              />
            </Grid>
            <Grid item xs={6} sm={4}>
              <FormControlLabel
                value="Prueba"
                control={
                  <Checkbox
                    checked={test}
                    onChange={handleChangeTypeService}
                    inputProps={{ "data-name": "test" }}
                  />
                }
                label="Prueba"
                labelPlacement="end"
                {...register("type_service[]")}
              />
            </Grid>
            <Grid item xs={6} sm={4}>
              <FormControlLabel
                value="Preventivo"
                control={
                  <Checkbox
                    checked={preventive}
                    onChange={handleChangeTypeService}
                    inputProps={{ "data-name": "preventive" }}
                  />
                }
                label="Preventivo"
                labelPlacement="end"
                {...register("type_service[]")}
              />
            </Grid>
            <Grid item xs={6} sm={4}>
              <FormControlLabel
                value="Acta de Entrega"
                control={
                  <Checkbox
                    checked={deliveryCertificate}
                    onChange={handleChangeTypeService}
                    inputProps={{ "data-name": "deliveryCertificate" }}
                  />
                }
                label="Acta de Entrega"
                labelPlacement="end"
                {...register("type_service[]")}
              />
            </Grid>
            <Grid item xs={6} sm={4}>
              <FormControlLabel
                value="Taller"
                control={
                  <Checkbox
                    checked={workshop}
                    onChange={handleChangeTypeService}
                    inputProps={{ "data-name": "workshop" }}
                  />
                }
                label="Taller"
                labelPlacement="end"
                {...register("type_service[]")}
              />
            </Grid>
          </Grid>

          <Grid item xs={12} sx={{ mt: 2 }}>
            <Grid
              container
              rowSpacing={2}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
              <Grid item xs={6} sm={4}>
               {/*  <TextField
                  label="Equipo"
                  variant="standard"
                  fullWidth
                  {...register("equipment")}
                />
                <small>{errors.equipment?.message}</small> */}

                <FormControl variant="standard" sx={{ width: "100%" }}>
                  <InputLabel id="equipment">Equipo</InputLabel>
                  <Select
                    labelId="equipment"
                    id="equipment"
                    label="Equipo"
                    fullWidth
                    {...register("equipment")}
                    value={equipment}
                    onChange={handleChangeEquipment}
                  >
                    <MenuItem>
                      <em>Seleccione una opción</em>
                    </MenuItem>
                    <MenuItem value="Cerradoras y grapadoras de cajas">Cerradoras y grapadoras de cajas</MenuItem>
                    <MenuItem value="Codificadores">Codificadores</MenuItem>
                    <MenuItem value="Dosificadores para líquidos y viscosos">Dosificadores para líquidos y viscosos</MenuItem>
                    <MenuItem value="Dosificadores para granulados, polvo y por peso">Dosificadores para granulados, polvo y por peso</MenuItem>
                    <MenuItem value="Empacadoras al vacío">Empacadoras al vacío</MenuItem>
                    <MenuItem value="Empacadoras horizontales automáticas">Empacadoras horizontales automáticas</MenuItem>
                    <MenuItem value="Elevadores">Elevadores</MenuItem>
                    <MenuItem value="Empacadoras Sachet y Stick Pack">Empacadoras Sachet y Stick Pack</MenuItem>
                    <MenuItem value="Envolvedoras">Envolvedoras</MenuItem>
                    <MenuItem value="Etiqueteadoras">Etiqueteadoras</MenuItem>
                    <MenuItem value="Equipos de Manejo de Materiales">Equipos de Manejo de Materiales</MenuItem>
                    <MenuItem value="Selladoras">Selladoras</MenuItem>
                    <MenuItem value="Sistema de Termoencogido">Sistema de Termoencogido</MenuItem>
                    <MenuItem value="Flejadoras">Flejadoras</MenuItem>
                    <MenuItem value="Precintadora y Cocedura de sacos">Precintadora y Cocedura de sacos</MenuItem>
                    <MenuItem value="Otros equipos">Otros equipos</MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={6} sm={4}>
                <TextField
                  label="Modelo"
                  variant="standard"
                  fullWidth
                  {...register("model")}
                />
                <small>{errors.model?.message}</small>
              </Grid>

              <Grid item xs={6} sm={4}>
                <TextField
                  label="Serial"
                  variant="standard"
                  fullWidth
                  {...register("series")}
                />
                <small>{errors.series?.message}</small>
              </Grid>
            </Grid>
          </Grid>
          
          <Grid item xs={12} sx={{ mt: 3 }}>
            <TextField
              label="1. Diligenciar en caso de Servicio Técnico o Garantía"
              variant="standard"
              multiline
              fullWidth
              rows={4}
              {...register("description_one")}
            />
          </Grid>

          <Grid item xs={12} sx={{ mt: 3 }}>
            <TextField
              label="2. Aplica solo para Post venta."
              variant="standard"
              multiline
              fullWidth
              rows={4}
              {...register("description_two")}
            />
          </Grid>

          <Grid item xs={12} sx={{ mt: 2 }}>
            <DetailProducts
              products={orderData?.description_three}
              register={register}
            />
          </Grid>

          <Grid item xs={12}>
            <FormControl>
              <FormLabel sx={{ mb: 2 }} id="demo-form-control-label-placement">
                Satisfacción del Servicio
              </FormLabel>
              <RadioGroup
                row
                aria-labelledby="demo-form-control-label-placement"
                name="service_satisfaction"
                value={serviceSatisfaction}
                onChange={handleChangeServiceSatisfaction}
              >
                <FormControlLabel
                  control={<Radio {...register("service_satisfaction")} />}
                  value="1"
                  label="1"
                  labelPlacement="top"
                />
                <FormControlLabel
                  control={<Radio {...register("service_satisfaction")} />}
                  value="2"
                  label="2"
                  labelPlacement="top"
                />
                <FormControlLabel
                  control={<Radio {...register("service_satisfaction")} />}
                  value="3"
                  label="3"
                  labelPlacement="top"
                />
                <FormControlLabel
                  control={<Radio {...register("service_satisfaction")} />}
                  value="4"
                  label="4"
                  labelPlacement="top"
                />
                <FormControlLabel
                  control={<Radio {...register("service_satisfaction")} />}
                  value="5"
                  label="5"
                  labelPlacement="top"
                />
              </RadioGroup>
            </FormControl>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
