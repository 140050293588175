import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
//import { createTheme, ThemeProvider } from '@mui/material/styles';
import Alert from '@mui/material/Alert';


import { useRef, useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import useInput from '../../hooks/useInput';
import useToggle from '../../hooks/useToggle';

import axios from '../../api/axios';
import Copyright from '../layout/Copyright';
//import { styled } from "@mui/material/styles";

import useAuth from "../../hooks/useAuth";

const LOGIN_URL = '/auth';

const SignIn = () => {

    const { auth, setAuth } = useAuth();

    const navigate = useNavigate();

    const location = useLocation();
    const from = location.state?.from?.pathname || "/admin";

    const userRef = useRef();
    const errRef = useRef();

    const [user, resetUser, userAttribs] = useInput('user', '')
    const [pwd, setPwd] = useState('');
    const [errMsg, setErrMsg] = useState('');
    const [check, toggleCheck] = useToggle('persist', false);

    useEffect(() => {
        userRef.current.focus();

        /* 
        if(auth?.name){
          console.log('auth', auth);
          navigate(from, { replace: true });
        } 
        */

    }, [])

    useEffect(() => {
        setErrMsg('');
    }, [user, pwd])


    const handleSubmit = async (e) => {
      e.preventDefault();

      console.log(user, pwd);

      try {
          const response = await axios.post(LOGIN_URL,
              JSON.stringify({ user, pwd }),
              {
                  headers: { 'Content-Type': 'application/json' },
                  withCredentials: true
              }
          );

          const accessToken = response?.data?.accessToken;
          const roles = response?.data?.roles;
          const username = response?.data?.username;
          setAuth({ username, user, pwd, roles, accessToken });
          resetUser();
          setPwd('');
          navigate(from, { replace: true });
      } catch (err) {
          if (!err?.response) {
              setErrMsg('No Server Response');
          } else if (err.response?.status === 400) {
              setErrMsg('Missing Username or Password');
          } else if (err.response?.status === 401) {
              setErrMsg('Unauthorized');
          } else {
              setErrMsg('Login Failed');
          }
          errRef.current.focus();
      }
  }


  return (
    
      <Container maxWidth="xs">
          
        <Paper elevation={6}>
          
          <Box
            sx={{
              marginTop: 8,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              padding: "20px",
              backgroundColor: "white"
            }}
          >
            <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Iniciar Sesión
            </Typography>
            
            <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
              <TextField
                margin="normal"
                fullWidth
                id="user"
                ref={userRef}
                label="Usuario"
                autoComplete="off"
                {...userAttribs}
              />
              <TextField
                margin="normal"
                required
                fullWidth
                label="Contraseña"
                type="password"
                id="password"
                autoComplete="current-password"
                onChange={(e) => {
                  console.log('pwd', e.target.value)
                  setPwd(e.target.value)}
                }
              />
              <FormControlLabel
                control={<Checkbox color="primary" id="persist" onChange={toggleCheck} checked={check} />}
                label="Confiar en este dispositivo"
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                Ingresar
              </Button>
              { errMsg ? (
                <Alert ref={errRef} severity="error">{errMsg}</Alert>
              ) : null}
              
            </Box>
          <Copyright sx={{ mt: 2 }} />
          </Box>

        </Paper>          
      </Container>
    
  )
}

export default SignIn