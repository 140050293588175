import {Container} from '@mui/material'
import Copyright from './Copyright'


const Footer = () => {
  return (
    <footer style={{bottom:0, position: 'fixed', width: '100%'}}>
        <Container maxWidth='sm'>
            <Copyright/>
        </Container>
    </footer>
  )
}

export default Footer