import { useRef, useState, useEffect } from "react";

import { useForm } from "react-hook-form";

import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Paper from "@mui/material/Paper";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import CustomerData from "./CustomerData";
import ServiceData from "./ServiceData";
import Signatures from "./Signatures";

import useAxiosPrivate from "../../hooks/useAxiosPrivate";

import { useParams, Link } from "react-router-dom";

const steps = ["Datos del Cliente", "Detalle del Servicio", "Firmar"];

function getStepContent(step, register, setValue, orderData, errors) {
  switch (step) {
    case 0:
      return <CustomerData register={register} orderData={orderData}  errors={errors} />;
    case 1:
      return <ServiceData register={register} orderData={orderData}  errors={errors} />;
    case 2:
      return <Signatures register={register} setValue={setValue} orderData={orderData} errors={errors} />;
    default:
      throw new Error("Unknown step");
  }
}

const theme = createTheme();

export default function ServiceOrder() {


  const [orderId, setOrderId] = useState(0);
  const [orderNo, setOrderNo] = useState(0);

  const [orderData, setOrderData] = useState({});

  let { id } = useParams();
  const axiosPrivate = useAxiosPrivate();

  // Editanto
  useEffect(() => {
    if (id) {
      let isMounted = true;
      const controller = new AbortController();

      const getOrder = async () => {
        try {
          const response = await axiosPrivate.get(`/orders/${id}`, {
            signal: controller.signal,
          });

          const data = response.data;

          if(isMounted){

            for (const property in data) {
              setValue(property, data[property]);
            }
            
            setOrderId(data._id);
            setOrderNo(data.order_no);

            setOrderData(data);
          }
          
        } catch (err) {
          console.error(err);
        }
      };

      getOrder();

      return () => {
        isMounted = false;
        controller.abort();
      };
    }
  }, []);

  const [activeStep, setActiveStep] = useState(0);


  const {
    register,
    getValues,
    handleSubmit,
    //watch,
    setValue,
    formState: { errors },
  } = useForm();


  console.log(errors);
  //console.log(watch("business_name"));

  const handleNext = () => {
    setActiveStep(activeStep + 1);
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  const [errMsg, setErrMsg] = useState("");
  const errRef = useRef();

  const onSubmit = async (data) => {
    const API_URL = "/orders";

    setOrderData(data);

    try {
      let response = {};
      if (orderNo == 0) {
        response = await axiosPrivate.post(API_URL, JSON.stringify(data), {
          headers: { "Content-Type": "application/json" },
          withCredentials: true,
        });
      } else {
        response = await axiosPrivate.put(
          API_URL + `/${orderId}`,
          JSON.stringify(data),
          {
            headers: { "Content-Type": "application/json" },
            withCredentials: true,
          }
        );
      }

      setOrderId(response?.data?._id);
      setOrderNo(response?.data?.order_no);

      handleNext();
    } catch (err) {
      if (!err?.response) {
        setErrMsg("No Server Response");
      } else if (err.response?.status === 400) {
        setErrMsg("Missing Username or Password");
      } else if (err.response?.status === 401) {
        setErrMsg("Unauthorized");
      } else {
        setErrMsg("Error al Crear la Orden");
      }
      //errRef.current.focus();
    }
  };

  return (
    <Container component="main"  sx={{ mb: 4, p: {xs:1, md:3} }}>
      <Paper elevation={6} sx={{ my: { xs: 1, md: 6 }, p: { xs: 2, md: 3 } }}>
        <Typography component="h1" variant="h4" align="center">
          Orden de Servicio  {id ? `#${orderNo}` : ""}
        </Typography>
        <Stepper activeStep={activeStep} sx={{ pt: 3, pb: 5 }}>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
        <>
          {activeStep === steps.length ? (
            <>
              <Typography variant="h5" gutterBottom>
                ¡Proceso Finalizado!
              </Typography>
              <Typography variant="subtitle1">
                Su número de orden es #{orderNo}
                {/* , responder la encuenta de
                satisfacción <a href="#">AQUÍ</a> */}
              </Typography>

              <Button variant="outlined" size="small" sx={{mt: 3}} component={Link} to="/admin/surveys/new" >
                Diligenciar Encuesta
              </Button>
            </>
          ) : (
            <form onSubmit={handleSubmit(onSubmit)}>
              <p
                ref={errRef}
                className={errMsg ? "errmsg" : "offscreen"}
                aria-live="assertive"
              >
                {errMsg}
              </p>

              {getStepContent(activeStep, register, setValue, orderData, errors)}
              <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                {activeStep !== 0 && (
                  <Button onClick={handleBack} sx={{ mt: 3, ml: 1 }}>
                    Regresar
                  </Button>
                )}

                <Button
                  variant="contained"
                  //onClick={handleNext}
                  type="submit"
                  sx={{ mt: 3, ml: 1 }}
                >
                  {activeStep === steps.length - 1 ? "Confirmar" : "Siguiente"}
                </Button>
              </Box>
            </form>
          )}
        </>
      </Paper>
    </Container>
  );
}
