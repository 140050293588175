import { useState, useEffect } from "react";
import { useNavigate, useLocation, Link } from "react-router-dom";

import useAxiosPrivate from "../../hooks/useAxiosPrivate";

import { Paper, Typography, Button, IconButton, Stack } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import Title from "../layout/Title";

import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from '@mui/icons-material/Edit';

import useAuth from "../../hooks/useAuth";

const ROLES = {
  'Admin': 1950,
  'Support': 1985,
  'User': 2022
}

const columns = [
  { field: "createdAt", headerName: "Fecha de Registro", width: 200 },
  { field: "customer_name", headerName: "Cliente", width: 200 },
  { field: "name_diligence", headerName: "Persona que Diligencia", width: 300 },
  { field: "service_evaluate", headerName: "Servicio a Evaluar", width: 300 },
  //{ field: "order_id", headerName: "Orden No", width: 100, valueGetter: (params) => ( params.rows.order_id.order_no)},

  {
    field: "action",
    headerName: "Acciones",
    width: 130,
    renderCell: (params) => {

      const { row } = params;
      const id = row.id;

      const onDelete = async (e) => {
        console.log("Delete row");

        if (!window.confirm('¿Esta seguro de eliminar la encuesta?')) {
          return;
        }

         const API_URL = `surveys/${id}`;
        try {
          let response = {};
          await row.connection.delete(API_URL, {}, { withCredentials: true });
        } catch (err) {
          //setErrMsg("Error al Eliminar la Encuesta");
        }

      };
      return (
        <>
          <Stack direction="row" spacing={0}>
            <IconButton aria-label="edit" component={Link} to={`/admin/surveys/${id}`}>
              <EditIcon />
            </IconButton>
            { row?.isAdmin ? (
              <IconButton aria-label="delete" onClick={() => onDelete()}>
                <DeleteIcon />
              </IconButton>
            ) : (<></>)}
          </Stack>
        </>
      );
    },
  },
];

export default function SurveyList() {
  const { auth } = useAuth();
  const isAdmin = auth?.roles?.find(role => ROLES.Admin == role);

  const [surveys, setSurveys] = useState();
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    let isMounted = true;
    const controller = new AbortController();

    const getSurveys = async () => {
      try {
        const response = await axiosPrivate.get("/surveys", {
          signal: controller.signal,
        });

        const data = response.data;
        //Modify rows here:
        const modifiedData = data.map(element => {
          return { ...element, connection: axiosPrivate, isAdmin };
        });

        isMounted && setSurveys(modifiedData);
      } catch (err) {
        console.error(err);
        console.log('Redirect Login');
        //navigate("/login", { state: { from: location }, replace: true });
      }
    };

    getSurveys();

    return () => {
      isMounted = false;
      controller.abort();
    };
  }, []);

  return (
    <Paper elevation={6} sx={{ p: 2 }}>
      <Title>Encuestas</Title>

      <Button variant="outlined" size="small" component={Link} to="/admin/surveys/new" >
        Crear
      </Button>
      <br />
      <br />
      {surveys?.length > 0 ? (
        <DataGrid
          rows={surveys}
          columns={columns}
          pageSize={10}
          rowsPerPageOptions={[5]}
          //checkboxSelection
          autoHeight
          density="compact"
        />
      ) : (
        <Typography>No se encontraron Encuestas</Typography>
      )}
    </Paper>
  );
}
